@import '../../variables';

.button.espiral-btn {
  &:not(.is-rounded) {
    border-radius: 15px;
  }

  &.is-small:not(.is-rounded) {
    border-radius: 10px;
  }

  &.with-shadow {
    box-shadow: 0px 0px 30px -10px $primary;
  }
}