$primary: #14abf7;
$success: #22cb6e;
$blue: #004e8e;
$dark-blue: #004e8e;
$light-blue: #0d6da9;
$dark: #4d5858;
$gray: #546977;
$white: #ffffff;
$light-red: #f6b5b5;
$purple: #a551ff;
$green: #22cb6e; // TODO Remove references in favor of $success
$family-sans-serif: 'Ubuntu', sans-serif;
$footer-background-color: #006599;
$footer-padding: 0.75rem;