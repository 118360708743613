.carousel-window {
  overflow: hidden;
  background-color: #e2f4fe;
  background-image: url("../../../public/assets/home/Back03.svg"), url("../../../public/assets/home/Back04.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: right center, left top;


  .carousel-nav {
    padding: 5px 5px 0;

    ul {
      display: flex;
      width: 100%;
    }

    li {
      width: 100%;
      padding: 12px;
      position: relative;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 0.85rem;
      flex: 1;
      min-width: 0;
      position: relative;
      user-select: none;
    }

    .underline {
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      height: 1px;
      background: blue;
    }

    li button {
      width: 20px;
      height: 20px;
      border: 0;
      background: #fff;
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      stroke: #000;
      margin-left: 10px;
      cursor: pointer;
      flex-shrink: 0;
    }
  }

  .mpos-tab {
    height: 450px;

    @media screen and (max-width: 768px) {
      height: 330px;
    }

    @media screen and (min-width: 1216px) {
      height: 500px;
    }

    .logos-list {
      img {
        height: 40px;
      }
    }
  }
}

.home-final-section {
  background-image: url("../../../public/assets/home/Background_principal.png"), url("../../../public/assets/home/Back05.svg");
  background-position: bottom right -712px, top 50px right 50px;
  background-repeat: no-repeat, no-repeat;

  .community-cards {
    .card {
      border-radius: 35px;
      box-shadow: 3px 5px 20px -10px grey;
    }
  }
}

.partners-list {
  img {
    height: 75px;
  }
}