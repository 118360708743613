#company-banner {
  background-color: #cdedfc;
  background-image: url('../../../public/assets/common/Back02_1.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .hero-body {
    .espiral-banner-logo {
      height: 75px;
    }

    .company-img {
      position: absolute;
      bottom: -150px;
    }
  }
}

#company-main {
  background-image: url('../../../public/assets/dashboard/Back02_3.svg'),
    url('../../../public/assets/dashboard/back03.svg');
  background-repeat: no-repeat, no-repeat;
  background-position: right top -3%, bottom 25% right;
  background-size: contain, contain;

  .company-vertical-line {
    background-image: url('../../../public/assets/company/timeline.svg');
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: 768px) {
      background-image: none;
    }
  }
}