@import '../../variables';

// TODO: create new class for this color
.start-today-footer .container h3 {
  color: $light-blue;
}

.start-today-footer {
  background-image: url("../../../public/assets/home/Back06.svg"), url("../../../public/assets/footer/21_People-8.png");
  background-repeat: no-repeat, no-repeat;
  background-size: auto, auto;
  background-position: center center, center bottom -150px;

  min-height: 750px;

  button,
  a {
    color: $primary;
    width: 300px;

    &:hover,
    &:focus {
      color: $primary;
    }
  }

  .people {
    width: 55%;
  }
}

.footer-links {
  background-color: $light-blue;

  
  a {
    color: $white;
    
    &:hover,
    &:focus {
      color: $white;
      text-decoration: underline;
    }

    &.active-footer-item {
      color: $primary;
      font-weight: 700;
    }
  }
}

.clip-path-container {
  position: relative;

  .clip-path {
    position: absolute;
    background-color: $light-blue;
    clip-path: ellipse(66% 85% at 54% 130%);
    bottom: 0;
    right: 0;
    left: 0;
  }
}


footer {
  .is-flex {
    gap: 1rem;
  }

  a.has-text-white:hover {
    text-decoration: underline;
  }
}