@import 'variables';

@keyframes mainBackground {
  0% {
    background-position-y: -300px;
    background-position-x: 0px;
  }

  25% {
    background-position-y: -400px;
    background-position-x: 100px;
  }

  50% {
    background-position-y: -350px;
    background-position-x: 50px;
  }

  75% {
    background-position-y: -310px;
    background-position-x: -10px;
  }

  100% {
    background-position-y: -300px;
    background-position-x: 0px;
  }
}

.top-banner-image {
  img {
    @media (min-width: 1024px) and (max-width: 1100px) {
      max-width: 135%;
    }

    @media screen and (min-width: 769px) and (max-width: 1023px) {
      max-width: 175%;
    }
  }
}

.main-background {
  background-image: url("../public/assets/Images/Background_principal.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -300px;

  animation: mainBackground 10s linear infinite;
}

.active-nav-item {
  color: $primary !important;
  font-weight: 700;
}

.title {
  color: $dark-blue;
  font-weight: 400;
}

.has-dark-blue-color {
  color: $dark-blue;
}

.has-gray-color {
  color: $gray;
}

.docs-main-content {
  scroll-padding-top: 152px; /* height of the navbar */
  scroll-margin-top: 152px; /* height of the navbar */
  
  h1, h2, h3, h4, h5, h6, p {
    margin-bottom: 1rem;
  }
}