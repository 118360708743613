.table-of-contents {
    border: 1px solid #eaecef;
    border-radius: 2px;
    background-color: #f6f8fa;
    line-height: 2;
    overflow: auto;
    max-height: 20rem;
    margin-bottom: 3rem;

    a:hover {
        text-decoration: underline;
    }
}