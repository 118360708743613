@import "~bulma/bulma";

$banner-breakpoint-1: 968px;
$banner-breakpoint-2: 862px;

#home-banner {
  background-image: url("../../../public/assets/home/Background_principal.png");
  background-position-y: bottom;
  background-position-x: -1212px;
  position: relative;
  z-index: 2;

  .banner-main-title {
    @media screen and (max-width: 450px) {
      font-size: 2rem !important;
    }
  }

  .home-banner-main-cta {
    @media screen and (max-width: 768px) {
      margin-left: -3rem;
      margin-right: -3rem;
    }
  }

  .home-banner-images {
    img {
      display: block;

      &.banner-dash {
        right: -110%;
        top: 200px;
        transform: scale(2.5);

        @media screen and (min-width: $banner-breakpoint-2) {
          right: -90%;
          top: 165px;
        }

        @media screen and (min-width: $banner-breakpoint-1) {
          right: -50%;
          top: 165px;
          transform: scale(2);
        }

        @media screen and (min-width: $desktop) {
          transform: scale(1.3);
          top: 75px;
        }
      }

      &.banner-mpos {
        position: absolute;
        bottom: -200%;
        width: 100%;
        left: -50px;

        @media screen and (min-width: $banner-breakpoint-2) {
          bottom: -170%;
          left: -75px;
        }

        @media screen and (min-width: $banner-breakpoint-1) {
          bottom: -125%;
          width: 70%;
          left: -50px;
        }


        @media screen and (min-width: $desktop) {
          bottom: -35%;
          width: 60%;
          left: 0;
        }
      }

      &.banner-card {
        position: absolute;
        bottom: -200%;
        width: 120%;
        left: 40%;

        @media screen and (min-width: $banner-breakpoint-2) {
          bottom: -170%;
          width: 100%;
          left: 22%;
        }

        @media screen and (min-width: $banner-breakpoint-1) {
          bottom: -125%;
          width: 80%;
          left: 22%;
        }

        @media screen and (min-width: $desktop) {
          bottom: -33%;
          width: 60%;
          left: 35%;
        }
      }

    }
  }

  .cards-columns {
    // height: 730px;
    // margin-bottom: -5rem;

    .column {
      @media screen and (max-width: 475px) {
        width: 100%;
      }
    }

    @media screen and (min-width: 985px) {
      height: 492px;
      margin-bottom: -15rem;
    }
  }
}