@import '../../variables';

$navbar-dropdown-border-radius: 20px;

nav.navbar {
  background-color: transparent;

  &.scrolled {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  #navbar-espiral {
    &.is-active {
      .navbar-dropdown {

        .nav-dropdown-left {
          display: none;
        }

        img {
          display: none;
        }
      }
    }

    .navbar-dropdown {
      padding: 0;
      border-top: none;
      border-radius: $navbar-dropdown-border-radius;

      .nav-dropdown-left {
        background-color: #ecf4f6;
        border-top-left-radius: $navbar-dropdown-border-radius;
        border-bottom-left-radius: $navbar-dropdown-border-radius;
        min-width: 200px;
      }
    }


    .navbar-item.has-dropdown:focus .navbar-link,
    .navbar-item.has-dropdown:hover .navbar-link,
    .navbar-item.has-dropdown.is-active .navbar-link,
    a.navbar-item:focus,
    a.navbar-item:focus-within,
    a.navbar-item:hover,
    a.navbar-item.is-active,
    .navbar-link:focus,
    .navbar-link:focus-within,
    .navbar-link:hover,
    .navbar-link.is-active {
      background-color: transparent;
      color: $primary !important;
    }
  }

}