#productos-ecommerce-banner {
  background-color: #d9f3e6;
  background-image: url('../../../../public/assets/common/Back02_1.svg'), url("../../../../public/assets/common/Back01.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: left -5px top 100px, right -450px bottom;
  background-size: auto, 75%;

  img {
    display: block;
    width: 80%;
    margin: auto;
  }
}

#productos-ecommerce-main {

  .que-esperar {
    background-image: url('../../../../public/assets/ecommerce/Back01.svg');
    background-repeat: no-repeat;
  }

  .que-esperar-2 {
    background-image: url('../../../../public/assets/ecommerce/back02.svg');
    background-repeat: no-repeat;
    background-position: right;
  }

  .solucion-que-se-adapte {
    background-image: url('../../../../public/assets/ecommerce/Back03_3.svg');
    background-repeat: no-repeat;
  }

  .logos-list {
    img {
      height: 50px;
    }
  }
}