#productos-mpos-banner {
  background-color: #efe3fb;
  background-image: url('../../../../public/assets/common/Back02_1.svg'), url("../../../../public/assets/common/Back01.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: left -5px top 100px, right -450px bottom;
  background-size: auto, 75%;

  img {
    display: block;
    width: 80%;
    margin: auto;
  }
}

#productos-mpos-main {
  .terminales {
    background-image: url('../../../../public/assets/mpos/Back03_1.svg');
    background-repeat: no-repeat;
  }

  .velocidad-y-comodidad {
    img {
      max-width: 400px;
    }
  }

  .conexion-tiempo-real {
    background-image: url('../../../../public/assets/mpos/Back04_1.svg');
    background-repeat: no-repeat;
    background-position: right;

    img {
      max-width: 400px;
    }
  }

  .logos-list {
    img {
      height: 75px;
    }
  }

  .cobra-mas {
    background-image: url('../../../../public/assets/mpos/back05.svg');
    background-repeat: no-repeat;

    img {
      height: 400px;
    }

  }
}