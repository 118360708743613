#productos-dashboard-banner {
  background-color: #cdedfc;
  background-image: url('../../../../public/assets/common/Back02_1.svg'), url("../../../../public/assets/dashboard/back01.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: left -5px top 100px, right -250px bottom;
  background-size: auto, 55%;

  .hero-body {
    .tablero-de-control-img {
      transform: scale(1.15);
      position: absolute;
    }
  }
}

#productos-dashboard-main {
  background-image: url('../../../../public/assets/dashboard/Back02_3.svg'),
    url('../../../../public/assets/dashboard/back03.svg'),
    url('../../../../public/assets/dashboard/back04.svg');
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-position: right top -3%, bottom 25% right, bottom right 25%;
  background-size: contain, contain, 40%;


  .toma-decisiones {

    img {
      transform: scale(1.15);
    }
  }


  .control-total {

    img {
      max-width: 175px;
    }
  }

  .descubre-dashboard {
    background-repeat: no-repeat;
    background-position: center;
  }
}