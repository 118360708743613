@import '../../variables';
@import "~bulma/bulma";

.espiral-card {
  border-radius: 35px;
  box-shadow: 0px 0px 20px -10px $green;
  min-width: 165px;
  max-width: 215px;
  margin: auto;
  min-height: 200px;

  img {
    width: 75px;
  }

  .card-more-text {
    display: none;

    @media (max-width: $tablet) {
      display: block;
    }

    &.expanded {
      display: block;
    }
  }

  &.expandable {
    cursor: pointer;
  }
}