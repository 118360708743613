#productos-tarjetas-banner {
  background-color: #cdedfc;
  background-image: url('../../../../public/assets/common/Back02_1.svg'), url("../../../../public/assets/common/Back01.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: left -5px top 100px, right -450px bottom;
  background-size: auto, 75%;

  img {
    display: block;
    width: 65%;
    margin: auto;
  }
}

#productos-tarjetas-main {
  background-image: url('../../../../public/assets/cards/Back01_2.svg'),
    url('../../../../public/assets/cards/04back.svg');
  background-repeat: no-repeat, no-repeat;
  background-position: right top -3%, bottom left 25%;
  background-size: contain, 25%;
}